var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"operations-expand-block-name"},[_vm._v("Account Transactions")]),_c('vxe-table',{staticClass:"common-grid",attrs:{"data":_vm.list,"size":"mini","border":"","round":"","row-class-name":_vm.row_class_name}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"40"}}),_c('vxe-table-column',{attrs:{"field":"isin","width":"120","title":"ISIN"}}),_c('vxe-table-column',{attrs:{"field":"q","width":"120","title":"Volume","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.q,"decimals":2,"color":"","sign":""}})]}}])}),_c('vxe-table-column',{attrs:{"field":"account","width":"120","title":"Account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.define_account(row.account))+" ")]}}])}),_c('vxe-table-column',{attrs:{"field":"flag","width":"120","title":"Transaction Flag"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.f && row.f === 'cms')?_c('span',[_vm._v("comission")]):_c('span',[_vm._v(_vm._s(row.f))])]}}])}),_c('vxe-table-column',{attrs:{"field":"flag","width":"120","title":"Cash Flow","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.cf)?_c('span',[_vm._v("✓")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }