<template>
    <div>
        <div class="operations-expand-block-name">Account Transactions</div>
        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid"
            border
            round
            :row-class-name="row_class_name">
                <vxe-table-column type="seq" width="40"></vxe-table-column>
                <vxe-table-column field="isin" width="120" title="ISIN"></vxe-table-column>
                <vxe-table-column field="q" width="120" title="Volume" align="right">
                    <template v-slot="{ row }">
                        <FormattedNumber :value="row.q" :decimals="2" color sign />
                    </template>
                </vxe-table-column>
                <vxe-table-column field="account" width="120" title="Account">
                    <template v-slot="{ row }">
                        {{define_account(row.account)}}
                    </template>
                </vxe-table-column>
                <vxe-table-column field="flag" width="120" title="Transaction Flag">
                    <template v-slot="{ row }">
                        <span v-if="row.f && row.f === 'cms'">comission</span>
                        <span v-else>{{row.f}}</span>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="flag" width="120" title="Cash Flow" align="center">
                    <template v-slot="{ row }">
                        <span v-if="row.cf">&#10003;</span>
                    </template>
                </vxe-table-column>
        </vxe-table>
    </div>
</template>

<script>
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    components: { FormattedNumber },

    props: {
        list: {
            required: true,
            type: Array
        },
        accounts: {
            required: true,
            type: Array
        }
    },

    methods: {
        row_class_name({ column, row }){
            if (row.q < 0)
                return 'col-credit';
            else
                return 'col-debit';
        },

        define_account(account){
            if (account && '$oid' in account && account['$oid']) {
                let acc = account['$oid']
                let acc_data = this.accounts.find(item => item._id === acc)
                return acc_data.name
            }
            return ''
        }
    }
}
</script>
